import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap'
import React from 'react';
import ReactDOM from 'react-dom';
import './App.css'
import './index.css';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ScrollToTop from './routers/ScrollToTop'

console.log("%c                                                                                          ", 'background-color:#2d65b0; color: #ffffff;');
console.log("%c                   ▒▒                                 ▒▒                                  ", 'background-color:#2d65b0; color: #ffffff;');
console.log("%c                 ▒▓▓▓▓▓▒                           ▒▓▓▓▓▓▒                                ", 'background-color:#2d65b0; color: #ffffff;');
console.log("%c               ▒▓▓▓▓▓▓▓▓▓▒▒                      ▒▓▓▓▓▓▓▓▓▓▒                              ", 'background-color:#2d65b0; color: #ffffff;');
console.log("%c                ▒▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▒                               ", 'background-color:#2d65b0; color: #ffffff;');
console.log("%c                   ▒▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▒▒                                 ", 'background-color:#2d65b0; color: #ffffff;');
console.log("%c                    ▓▓▓▓▓▓▒▒▒▒▒▒▒▓▓▓▓▓▓▓▓▓▒▒▒▒▒▒▒▓▓▓▓▓▓                                   ", 'background-color:#2d65b0; color: #ffffff;');
console.log("%c                    ▓▓▓▓▒          ▒▓▓▓▒           ▒▓▓▓                                   ", 'background-color:#2d65b0; color: #ffffff;');
console.log("%c                    ▓▓▒    ▒▒▓▓▒    ▒▓▒    ▒▒▓▒▒    ▒▓▓                                   ", 'background-color:#2d65b0; color: #ffffff;');
console.log("%c                    ▓▓    ▒▓▓▓▓▓▓▒   ▒    ▓▓▓▓▓▓▒    ▓▓                                   ", 'background-color:#2d65b0; color: #ffffff;');
console.log("%c                    ▓▓▒    ▓▓▓▓▓▓    ▓    ▒▓▓▓▓▓▒    ▓▓                                   ", 'background-color:#2d65b0; color: #ffffff;');
console.log("%c                    ▓▓▓▒     ▒▒     ▒▓▒     ▒▒▒     ▒▓▓                                   ", 'background-color:#2d65b0; color: #ffffff;');
console.log("%c                    ▓▓▓▓▒▒        ▒▒▒▒▒▒▒         ▒▓▓▓▓                                   ", 'background-color:#2d65b0; color: #ffffff;');
console.log("%c                    ▓▓▓▓▓▓▓▓▓▒▒▓▓▓▒     ▒▓▓▓▒▒▒▓▓▓▓▓▓▓▓                                   ", 'background-color:#2d65b0; color: #ffffff;');
console.log("%c                    ▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▒   ▒▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓                                   ", 'background-color:#2d65b0; color: #ffffff;');
console.log("%c                    ▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▒ ▒▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓                                   ", 'background-color:#2d65b0; color: #ffffff;');
console.log("%c                    ▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓                                   ", 'background-color:#2d65b0; color: #ffffff;');
console.log("%c                     ▒▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓                                    ", 'background-color:#2d65b0; color: #ffffff;');
console.log("%c                      ▒▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▒                                     ", 'background-color:#2d65b0; color: #ffffff;');
console.log("%c                       ▒▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▒                                      ", 'background-color:#2d65b0; color: #ffffff;');
console.log("%c                     ▒▒▓▒▒▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▒▒▓▒                                    ", 'background-color:#2d65b0; color: #ffffff;');
console.log("%c                   ▒▓▓▓▓▓▒▒▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▒▓▓▓▓▓▒▒                                 ", 'background-color:#2d65b0; color: #ffffff;');
console.log("%c                 ▒▓▓▓▓▓▓▓▓▒▒▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▒▒▓▓▓▓▓▓▓▓▓                                ", 'background-color:#2d65b0; color: #ffffff;');
console.log("%c                 ▒▓▓▓▓▓▓▓▓▓▒▒▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▒▓▓▓▓▓▓▓▓▓▓                                ", 'background-color:#2d65b0; color: #ffffff;');
console.log("%c                 ▒▓▓▓▓▓▓▓▓▒▒▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▒▒▓▓▓▓▓▓▓▓▓                                ", 'background-color:#2d65b0; color: #ffffff;');
console.log("%c                 ▒▓▓▓▓▓▓▓▒▒▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▒▒▓▓▓▓▓▓▓▓                                ", 'background-color:#2d65b0; color: #ffffff;');
console.log("%c                 ▒▓▓▓▓▓▓▒▒▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▒▒▓▓▓▓▓▓▓                                ", 'background-color:#2d65b0; color: #ffffff;');
console.log("%c                 ▒▓▓▓▓▓▒▒▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▒▒▓▓▓▓▓▓                                ", 'background-color:#2d65b0; color: #ffffff;');
console.log("%c                 ▒▓▓▓▓▒▒▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▒▒▓▓▓▓▓                       ▒▒       ", 'background-color:#2d65b0; color: #ffffff;');
console.log("%c                 ▒▓▓▓▒▒▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▒▒▓▓▓▓                                ", 'background-color:#2d65b0; color: #ffffff;');
console.log("%c                 ▒▓▓▒ ▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▒▒▓▓▓              ▒▒▓               ", 'background-color:#2d65b0; color: #ffffff;');
console.log("%c                   ▒ ▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▒▒▒            ▒▒▓▓▓  ▒▓▓▓▓▒▒       ", 'background-color:#2d65b0; color: #ffffff;');
console.log("%c                    ▒▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▒             ▒▓▓▓▓▒  ▒▒▓▓▓▓▓▓▒     ", 'background-color:#2d65b0; color: #ffffff;');
console.log("%c                      ▒▓▓▓▓▓▓▓▓▓▓▓▒▓▓▓▓▓▓▒▓▓▓▓▓▓▓▓▓▓▒              ▒▓▓▓▓  ▒ ▒▓▓▓▓▓▒       ", 'background-color:#2d65b0; color: #ffffff;');
console.log("%c      ▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▒▒▒▒▒▒▒▒▒ ▒ ▒▒▒▒▒▒ ▒ ▒▒▒▒▒▒▒▒▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▒▓▓▒                   ", 'background-color:#2d65b0; color: #ffffff;');
console.log("%c                          ▒▒▒▒▒▒▒▒ ▒▒▒▒▒▒ ▒ ▒▒▒▒▒▒                 ▒▓▓▓▓▓▓▓▓▓▓▒▒          ", 'background-color:#2d65b0; color: #ffffff;');
console.log("%c                           ▒▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▒                     ▒▒▒▒▒▒▒              ", 'background-color:#2d65b0; color: #ffffff;');
console.log("%c                          ▒▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▒                                         ", 'background-color:#2d65b0; color: #ffffff;');
console.log("%c                         ▒▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▒                                       ", 'background-color:#2d65b0; color: #ffffff;');
console.log("%c                       ▒▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▒                                      ", 'background-color:#2d65b0; color: #ffffff;');
console.log("%c                                                                                          ", 'background-color:#2d65b0; color: #ffffff;');
console.log("%c LessonSharing Learn version " + process.env.REACT_APP_VERSION + " " + process.env.NODE_ENV, 'font-weight: bold; font-size: 20px; background-color:#2d65b0; color: #ffffff;')

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <ScrollToTop />
      <App />
    </BrowserRouter>
  </React.StrictMode>
  ,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
