import axios from "axios"
const mainApi = process.env.REACT_APP_API_URL;

export const callApi = async (method, url, data = null) => {
    const headers = { 'Authorization': localStorage.getItem('token') || null }
    const options = {
        method: method,
        headers: headers,
        url: mainApi + url,
        data: data
    }
    const result = await axios(options).then(res => {
        if (res.data) {
            return res.data
        }
    }).catch((err) => {
        return { errorStatus: err.response.status }
    })

    return result
}
