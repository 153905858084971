import { useEffect, useState } from "react";
import {
    Navbar,
    NavDropdown,
    Container,
    Nav,
    InputGroup,
    Badge,
    Button
} from "react-bootstrap"
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAuthContext } from "../../contexts/AuthContext";
import DarkMode from "../../themes/DarkMode.tsx"
import { callApi } from "../Request";

export default function MainNavbar({ expandedNavbar, setExpandedNavbar }) {
    const { userHasAuthenticated } = useAuthContext();
    const match = useLocation()
    const [admin, setAdmin] = useState();
    const [unreadFeedbacks, setUnreadFeedbacks] = useState()
    const [mainMenu] = useState([
        {
            name: "Home",
            path: "/"
        },
        {
            name: "Cours",
            path: "/articles"
        }
    ])
    const [adminMenu, setAdminMenu] = useState([
        {
            name: "Dashboard",
            path: "/admin"
        },
        {
            name: "Articles",
            path: "/admin/articles"
        },
        {
            name: "Tags",
            path: "/admin/tags"
        },
        {
            name: "Categories",
            path: "/admin/categories"
        },
        {
            name: "Feedbacks",
            path: "/admin/feedbacks"
        }
    ])
    const redirect = useNavigate()

    useEffect(() => {
        if (localStorage.getItem("token")) {
            const getRoleUrl = "/auth/get-role"
            const getUnreadNumberFeedbacksUrl = "/feedbacks/get/unread"
            callApi('get', getRoleUrl).then((res) => {
                if (res.role === 'admin') {
                    setAdmin(true);
                    callApi('get', getUnreadNumberFeedbacksUrl).then((res) => {
                        setUnreadFeedbacks(res.count)
                    })
                }
                if (res.role === 'superadmin') {
                    setAdminMenu(prevState => [...prevState, { name: "Users", path: "/admin/users" }, { name: "Schools", path: "/admin/schools" }])
                    setAdmin(true);
                    callApi('get', getUnreadNumberFeedbacksUrl).then((res) => {
                        setUnreadFeedbacks(res.count)
                    })
                }
            })
        }
    }, [])

    const handleLogout = () => {
        localStorage.clear()
        redirect('/')
        userHasAuthenticated(false);
    }

    const handleTogglerClick = () => {
        expandedNavbar ? setExpandedNavbar(false) : setExpandedNavbar(true)
    }

    return (
        <Navbar id="MainNav" className="container learnHeader" sticky='top' expanded={expandedNavbar} expand="lg">
            <Navbar.Brand>
                <img src="/logo.svg" alt="logo" className="bi me-2" width="40" height="32" />
                <span className="fs-4 text-white">Learn <Badge pill bg="primary">Beta</Badge></span>
            </Navbar.Brand>
            <Navbar.Toggle onClick={handleTogglerClick} aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Container >
                    <Nav className="me-auto d-flex justify-content-center gap-lg-5">
                        {mainMenu.map((element) => {

                            const isActive = element.path === match.pathname ? "activeCustom" : ""
                            return <NavLink
                                expandedNavbar={expandedNavbar}
                                handleTogglerClick={handleTogglerClick}
                                activeClassName={isActive}
                                name={element.name}
                                to={element.path}
                                key={element.name}
                            />
                        })}

                        {
                            admin
                            &&
                            <NavDropdown title="Admin" id="navbarScrollingDropdown">
                                {adminMenu.map((menuElement) => {
                                    return (
                                        <NavDropdown.Item
                                            as={Link}
                                            name={menuElement.name}
                                            to={menuElement.path}
                                            key={menuElement.name}
                                            onClick={expandedNavbar ? handleTogglerClick : null}
                                        >
                                            {menuElement.name}
                                            {menuElement.name === 'Feedbacks' && <span> <Badge bg="secondary">{unreadFeedbacks}</Badge></span>}
                                        </NavDropdown.Item>
                                    );
                                })}
                            </NavDropdown>
                        }

                    </Nav>
                </Container>
                <Navbar.Text className="d-flex gap-lg-2">
                    <InputGroup>
                        <DarkMode />
                    </InputGroup>
                    <Button onClick={handleLogout}>
                        LOGOUT
                    </Button>
                </Navbar.Text>
            </Navbar.Collapse>
        </Navbar>
    )
}

function NavLink({ expandedNavbar, handleTogglerClick, name, to, activeClassName }) {
    const navLinkStyle = {
        textDecoration: "none",
        color: "#eee"
    }
    return (
        <Navbar.Text as="div" >
            <Link onClick={expandedNavbar ? handleTogglerClick : null} style={navLinkStyle} className={activeClassName} to={to}>{name}</Link>
        </Navbar.Text >
    )
}