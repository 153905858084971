import { Row } from "react-bootstrap";

export default function Loader() {
    return (
        <Row style={{ height: '100vh' }} className="d-flex flex-column justify-content-center">
            <div className="d-flex justify-content-center">
                <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
            </div>
        </Row>
    );
};
