import React, { useState, useEffect, Suspense, lazy } from 'react';
import { AuthContext } from './contexts/AuthContext'
import { callApi } from './components/Request';
import { Container } from 'react-bootstrap';
import { App as CapacitorApp } from '@capacitor/app';
import Navbar from './components/navbars/MainNavbar';
import Loader from './components/Loader'
const MainRouter = lazy(() => import('./routers/MainRouter'))
const Maintenance = lazy(() => import('./pages/user/Maintenance'));
const Login = lazy(() => import('./pages/user/Login'))

function App() {
    const [isAuthenticated, userHasAuthenticated] = useState();
    const [onMaintenance, setMaintenance] = useState()
    const [expandedNavbar, setExpandedNavbar] = useState(false)

    useEffect(() => {

        callApi('get', '/').then(res => {
            if (res.errorStatus) {
                setMaintenance(true)
            }
        })

        if (localStorage.getItem("token")) {
            const url = "/auth/verify"
            callApi('get', url).then((res) => {
                if (res.valid) {
                    userHasAuthenticated(true)
                }
            })
        }

        CapacitorApp.addListener('backButton', ({ canGoBack }) => {
            if (!canGoBack) {
                CapacitorApp.exitApp();
            } else {
                window.history.back();
            }
        });

    }, [])

    const handleClickOutside = () => {
        if (expandedNavbar) {
            setExpandedNavbar(false)
        }
    }

    if (onMaintenance) {
        return <Maintenance />
    }

    return (
        <AuthContext.Provider value={{ isAuthenticated, userHasAuthenticated }}>
            {isAuthenticated && <Navbar expandedNavbar={expandedNavbar} setExpandedNavbar={setExpandedNavbar} />}
            <Suspense fallback={<Loader />}>
                <Container onClick={handleClickOutside} id="content">
                    {isAuthenticated ? <MainRouter /> : <Login />}
                </Container>
            </Suspense>
        </AuthContext.Provider>
    )
}

export default App